
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { formatLocalTime } from '@/utils/format-time';

import { useCampaignCommissionOverview, useCampaignCommissions } from '@/composables/api';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import PanelGroup from './components/PanelGroup.vue';
import { getChannels, MAX_PER_PAGE } from '@/apis';

export default defineComponent({
  components: {
    Filter,
    PanelGroup
  },
  setup() {
    // query items
    const page = ref(1);
    const pageSize = ref(15);
    const channels = ref();
    const channelOptions = ref([]);
    const channelIds = ref();

    const { data, isLoading, isFetching } = useCampaignCommissions({ page, pageSize, channelIds });
    const { data: overview } = useCampaignCommissionOverview({ channelIds });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.MULTI_SELECTOR,
          label: '渠道名稱',
          placeholder: '請選擇',
          options: channelOptions.value
        }
      ];
    });

    const handleFilterChange = (event: FilterEvent) => {
      channelIds.value = event[0];
    };

    const fetchChannels = async() => {
      channels.value = (await getChannels({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    watch(channels, () => {
      channelOptions.value = channels.value.map(({ utmSource, id }) => ({ label: `${utmSource}`, value: `${id}` }));
    });

    onMounted(() => {
      fetchChannels();
    });

    return {
      page,
      data,
      overview,
      isLoading,
      isFetching,
      filterOptions,
      formatLocalTime,
      handleFilterChange
    };
  }
});
