import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de015468"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelGroup = _resolveComponent("PanelGroup")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_PanelGroup, {
        data: _ctx.overview?.data
      }, null, 8, ["data"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Filter, {
          options: _ctx.filterOptions,
          onChange: _ctx.handleFilterChange
        }, null, 8, ["options", "onChange"])
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"},
        "empty-text": "暫無資料"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "項次"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.id), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "渠道名稱"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.utmCampaign), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "廣告名稱"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(row.utmCampaign), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "廣告尺寸"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.utmSize), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "首訂金額",
            prop: "createdAt"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.initSubscriptionAmount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "續訂金額"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.renewalAmount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "首訂階層金額"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.subscriptionLevelAmount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "續訂階層金額"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.renewalLevelAmount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "首訂分潤"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString((+row.initCommissionPercent).toFixed(2)) + "%", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "續訂分潤"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString((+row.rebillCommissionPercent).toFixed(2)) + "%", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "階層分潤"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (test) => {
                return _createVNode(_component_el_table_column, {
                  align: "center",
                  label: `階層${test}`,
                  key: test
                }, {
                  default: _withCtx(({row}) => [
                    _createElementVNode("span", null, _toDisplayString(row.levelCommissions[test - 1]?.threshold || 0) + "→" + _toDisplayString((+row.levelCommissions[test - 1]?.percentage || 0).toFixed(2)) + "%", 1)
                  ]),
                  _: 2
                }, 1032, ["label"])
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ]),
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.page,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.data?.meta?.pageSize,
        layout: "prev, pager, next, jumper",
        total: _ctx.data?.meta?.total
      }, null, 8, ["currentPage", "page-size", "total"])
    ]),
    _: 1
  }))
}